import { useState } from "react";
import { Disclosure } from "@headlessui/react";
// import logoDark from "@/assets/logo/logo-dark.png";
// import logoWhite from "@/assets/logo/logo-white.png";
import CMSLink from "@/components/DynamicLink";
import Media from "@/components/Media";
import { HiOutlinePhone } from "react-icons/hi2";
import logoWhite from '@/assets/logo-white.png'
import Image from "next/image";
import Link from "next/link";

import { useRouter } from 'next/router';

function Hamburger({ isOpen, setIsOpen, backgroundGG, className }) {
  const genericHamburgerLine = `h-px w-6 my-1 rounded-full bg-white transition ease transform duration-300`;



  return (
    <Disclosure.Button
      className={`${className} inline-flex items-center justify-center rounded-md p-2 ${backgroundGG ? "text-white" : "text-white"
        }`}
    >
      <span className="sr-only">Open main menu</span>
      <span>
        <span
          className="flex flex-col h-12 w-12 rounded justify-center items-center group"
          onClick={() => setIsOpen(!isOpen)}
        >
          <span
            className={`${genericHamburgerLine} ${isOpen
              ? "rotate-45 translate-y-2 opacity-80 group-hover:opacity-100"
              : "opacity-80 group-hover:opacity-100"
              }`}
          />
          <span
            className={`${genericHamburgerLine} ${isOpen ? "opacity-0" : "opacity-80 group-hover:opacity-100"
              }`}
          />
          <span
            className={`${genericHamburgerLine} ${isOpen
              ? "-rotate-45 -translate-y-2.5 opacity-80 group-hover:opacity-100"
              : "opacity-80 group-hover:opacity-100"
              }`}
          />
        </span>
      </span>
    </Disclosure.Button>
  );
}

function NavLink({ data, label, backgroundGG }) {

  return (
    <CMSLink data={data} className={`btn-tertiary group ${backgroundGG ? "text-white" : "text-white"} border-r border-[#d4e7f6] last:border-r-0 px-5 hover:font-semibold`} >
      <span className='btn-tertiary-decoration'> {label} </span>
    </CMSLink>
  );
}


export default function Header({ scrollY, background }) {
  const [isOpen, setIsOpen] = useState(false);
  const backgroundGG = background || scrollY > 400;

  const router = useRouter()

  const headerData = {
    links: [
      {
        link: {
          label: 'Home',
          href: 'hero'
        },
      },
      {
        link: {
          label: 'Units',
          href: 'units'
        },
      },
      {
        link: {
          label: 'ROI Calculator',
          href: 'businessModel'
        },
      },
    ]
  }

  return (
    <>
      <Disclosure
        as="nav"
        className={`${backgroundGG ? `bg-black/20 backdrop-blur-sm` : "bg-transparent backdrop-blur-smex"
          } fixed w-screen top-0 z-40 transition duration-500 ease-in-out`}
      >
        <>
          <div className="mx-auto px-4 sm:px-6 lg:px-8 py-2 md:py-2 container flex justify-between h-20 sm:h-28 md:h-auto items-center">
            <div className="flex items-center justify-between gap-5 w-full xl:w-48">
              <Link href='/' className="relative aspect-[27/9] h-12  sm:h-16 md:h-20">
                <Image src={logoWhite} alt="logo" className="object-contain " fill={true} />
                {/* {backgroundGG ? (
                    ) : (
                      <Image src={logoWhite} alt="logo" fill className="object-contain" />
                    )} */}
                {/* <span className={`${backgroundGG ? 'text-gray-800' : 'text-white'} text-3xl`}>MENZ & PARTNER</span> */}
              </Link>
              <div className={`flex items-center xl:hidden`}>
                <a aria-label="phone number, call us" href='tel:+1 809 383 4991'>
                  <HiOutlinePhone className="h-7 w-7 text-white" />
                </a>
                {/* Mobile menu button */}
                <Hamburger
                  className={`${router.pathname === "/thank_you" || router.pathname === "/contact" ? "hidden" : ""}`}
                  isOpen={isOpen}
                  setIsOpen={setIsOpen}
                  backgroundGG={backgroundGG}
                />
              </div>
            </div>
            <div className="hidden xl:block">
              <div className="flex flex-grow justify-end">
                <div className="hidden md:ml-6 md:flex py-4 md:items-center ">
                  {headerData?.links?.map((el, i) => (
                    // <NavLink
                    //   key={i}
                    //   data={el.link}
                    //   label={el.link.label}
                    //   backgroundGG={backgroundGG}
                    // />
                    <button
                      key={`header-button-link-${i}`}
                      onClick={() => {
                        try {
                          document.getElementById(el.link.href).scrollIntoView({ behavior: 'smooth' });

                        } catch (e) {
                          console.log(e)
                        }
                        // Scroll to anchor 
                      }}
                      className={`${router.pathname === "/thank_you" || router.pathname === "/contact" ? "hidden" : ""} btn-tertiary group ${backgroundGG ? "text-white" : "text-white"} border-r border-[#d4e7f6] last:border-r-0 px-5 hover:font-semibold`} >
                      <span className='btn-tertiary-decoration'> {el.link.label} </span>
                    </button>

                  ))}
                  <a
                    href='tel:+1 809 383 4991'

                    className={`${router.pathname === "/thank_you" || router.pathname === "/contact" ? "hidden" : ""} btn-tertiary group ${backgroundGG ? "text-white" : "text-white"} border-r border-[#d4e7f6] last:border-r-0 px-5 hover:font-semibold`} >
                    <span className='btn-tertiary-decoration'> CONTACT </span>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div
            className={`flex flex-col px-4 overflow-hidden ${isOpen ? "max-h-screen opacity-100" : "max-h-0 opacity-0"
              }`}
            style={{
              transition: isOpen
                ? "max-height 1s cubic-bezier(0.4, 0, 0.2, 1), opacity 1s ease-out, transform 1s ease-out"
                : "max-height 1s ease-in-out, opacity 1s ease-in-out",
            }}
          >
            {headerData?.links?.map((el, index) => (
              <button onClick={() => {
                try {

                  // Scroll to anchor 
                  document.getElementById(el.link.href).scrollIntoView({ behavior: 'smooth' });
                } catch (e) {
                  router.push(`/#${el.link.href}`)
                }
                setIsOpen(false);
              }} key={`headerbutton-${index}`} className="xl:hidden border-t border-t-white pt-2 pb-2 border-opacity-60">
                <div
                  className={`overflow-hidden text-white px-5 ${isOpen ? "max-h-screen opacity-100" : "max-h-0 opacity-0"
                    }`}
                  style={{
                    transition: isOpen
                      ? "max-height 1s cubic-bezier(0.4, 0, 0.2, 1), opacity 1s ease-out, transform 1s ease-out"
                      : "max-height 1s ease-in-out, opacity 1s ease-in-out",
                  }}
                >
                  <p className="">{el.link.label}</p>
                </div>
              </button>
            ))}
            <a href='tel:+1 809 383 4991' className="xl:hidden border-t border-t-white pt-2 pb-2 border-opacity-60">
              <div
                className={`overflow-hidden text-white px-5 ${isOpen ? "max-h-screen opacity-100" : "max-h-0 opacity-0"
                  } text-center font-medium`}
                style={{
                  transition: isOpen
                    ? "max-height 1s cubic-bezier(0.4, 0, 0.2, 1), opacity 1s ease-out, transform 1s ease-out"
                    : "max-height 1s ease-in-out, opacity 1s ease-in-out",
                }}
              >
                <p className="">
                  CONTACT
                </p>
              </div>
            </a>
          </div>
        </>
      </Disclosure>
    </>
  );
}
