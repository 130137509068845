import ModalContainer from '@/components/ModalContainer';
import { cookiePreferencesAtom } from '@/pages/_app';
import { setCookies } from '@/utils/cookieUtilities';
import { Disclosure } from '@headlessui/react';
import { useAtom } from 'jotai';
import React, { useState } from 'react';
import { HiMinusSmall, HiPlusSmall } from 'react-icons/hi2';

export function CookieConsentModal() {
    const [cookiePreferences, setCookiePreferences] = useAtom(cookiePreferencesAtom);
    const [activeSlide, setActiveSlide] = useState('default')
    const [selectedCookieCategories, setSelectedCookieCategories] = useState(['essential'])


    function handleAccept(acceptedArray) {
        if (!acceptedArray?.length) return handleDecline();

        setCookiePreferences(acceptedArray)
        setCookies(acceptedArray);
    }

    function handleDecline() {
        setCookiePreferences([])
        setCookies([]);
    }

    const cookieCategories = {
        essential: {
            name: 'Essential',
            description: 'These cookies are necessary for the operation of the website and cannot be deactivated.',
            required: true,
            cookies: [
                {
                    name: 'Cookie Settings',
                    description: 'Stores your cookie preferences.',
                    key: 'cookiePreferences',
                }
            ]
        },
        analytics: {
            name: 'Analytics & Marketing',
            description: 'These cookies help us improve our website by anonymously analyzing its usage.',
            required: false,
            cookies: [
                {
                    name: 'Google Analytics',
                    description: 'Google Analytics is a web analysis service that provides you with tools to measure the success of your website or blogs in terms of marketing, content optimization, or e-commerce. Google Analytics originated from the web analysis company Urchin, which was acquired by Google at the end of 2005. With Google Analytics, you can measure the ROI of your online marketing efforts, as well as track your Flash, video, and social networking sites and applications.',
                    key: '_ga, _gcl_au, _ga_XXXXXXXXXX',
                },
            ]
        }
    }

    const slides = {
        default: (
            <div>
                <h1 className='text-xl font-medium'>This Website uses Cookies.</h1>
                <p className='text-gray-600 text-sm'>
                    These enable us to improve your user experience on our website. <br /> <br />
                    You can now or later decide which cookies you accept and which ones you don't (click on "Change cookie settings" in the footer of the page).
                    The best user experience is created when you accept the use of all cookies.
                </p>
                <div className="flex mt-4 justify-between flex-col md:flex-row">
                    <div className="text-gray-900 underline text-sm ">
                        <div className="flex space-x-2 text-gray-900 underline text-sm items-center justify-center md:justify-start">
                            <a className='focus:outline-none' href="/legal-notice" target='_blank' rel='noopener noreferrer'>Legal Notice</a>
                            <a className='focus:outline-none' href="/data-privacy" target='_blank' rel='noopener noreferrer'>Data Privacy</a>
                        </div>
                        <button
                            className='text-underline mt-2 text-center md:text-left focus:outline-none w-full'
                            onClick={() => setActiveSlide('settings')}>Advanced Settings
                        </button>
                    </div>
                    <div className="flex gap-4 flex-col md:flex-row mt-4 md:mt-0 justify-between">
                        <button
                            className='btn-primary'
                            onClick={() => handleDecline()}>Only essential Cookies</button>
                        <button
                            className='btn-primary'
                            onClick={() => handleAccept(Object.keys(cookieCategories))}>Accept all Cookies
                        </button>
                    </div>
                </div>
            </div>
        ),
        settings: (
            <div>
                <h1 className='text-xl font-medium'>Advanced Settings</h1>
                <p className='text-gray-600 text-sm'>
                    {/* Legen Sie hier fest, welche Cookies Sie akzeptieren möchten. */}
                </p>
                <div className="flex flex-col">
                    {Object.keys(cookieCategories).map((cookieCategory) => {
                        return (
                            <Disclosure as="div" key={cookieCategory} className=" border-b border-gray-200 py-2">
                                {({ open }) => (
                                    <>

                                        <div className="flex flex-col md:flex-row md:items-center items-start space-y-2 md:space-y-0 justify-between w-full">
                                            <div className="flex items-center">
                                                <input

                                                    type="checkbox"
                                                    className="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300"
                                                    checked={cookieCategories[cookieCategory].required || selectedCookieCategories.includes(cookieCategory)}
                                                    disabled={cookieCategories[cookieCategory].required}
                                                    onChange={(e) => {
                                                        if (e.target.checked) {
                                                            setSelectedCookieCategories([...selectedCookieCategories, cookieCategory])
                                                        } else {
                                                            setSelectedCookieCategories(selectedCookieCategories.filter((item) => item !== cookieCategory))
                                                        }
                                                    }}
                                                />
                                                <label htmlFor="comments" className="ml-3 block text-sm font-medium text-gray-700">
                                                    {cookieCategories[cookieCategory].name}
                                                </label>
                                            </div>
                                            <div className="flex space-x-1 items-center">

                                                <p className="text-gray-500 text-sm">{cookieCategories[cookieCategory].description}</p>
                                                {/* // Arrow Icon that toggles List of Cookies */}
                                                {/* <ChevronDownIcon className="h-5 w-5 text-gray-400 ml-6" aria-hidden="true" /> */}
                                                <Disclosure.Button className="flex items-start justify-between text-left text-gray-900">
                                                    <span className=" flex h-7 items-center">
                                                        {open ? (
                                                            <HiMinusSmall className="h-6 w-6" aria-hidden="true" />
                                                        ) : (
                                                            <HiPlusSmall className="h-6 w-6" aria-hidden="true" />
                                                        )}
                                                    </span>
                                                </Disclosure.Button>
                                            </div>



                                        </div>
                                        <Disclosure.Panel as="dd" className="mt-3 flex flex-col space-y-2 max-h-[30vh] overflow-y-auto">
                                            {cookieCategories[cookieCategory].cookies ? cookieCategories[cookieCategory].cookies?.map((cookie) => {
                            
                                                // Return name Key and description of Cookie, no Input
                                                return (
                                                    <>
                                                        <div className="space-x-2 ml-4 justify-between hidden md:flex">
                                                            <div className='text-sm text-gray-700'>{cookie.name}</div>
                                                            <div className='text-sm text-gray-700 p-2 bg-gray-100'>{cookie.key}</div>
                                                            <div className='text-gray-500 text-sm'>{cookie.description}</div>
                                                        </div>
                                                        {/* Mobile */}
                                                        <div className="ml-4 justify-between flex flex-col md:hidden border-t-2 pt-2">
                                                            <div className="grid grid-cols-2 items-center">

                                                                <div className='text-sm text-gray-700 font-medium'>{cookie.name}</div>
                                                                <div className='text-sm text-gray-700 p-2 bg-gray-100'>{cookie.key}</div>
                                                            </div>
                                                            <div className='text-gray-500 text-sm mt-2'>{cookie.description}</div>
                                                        </div>
                                                    </>
                                                )
                                            }) : (
                                                <span className='text-sm text-gray-500 ml-4'>Keine Cookies.</span>
                                            )}
                                        </Disclosure.Panel>
                                    </>

                                )}

                            </Disclosure>
                        )
                    })}
                </div>
                <div className="flex space-y-2 md:space-y-0 md:space-x-4 mt-4 justify-end flex-col md:flex-row">
                    <button
                        className='inline-flex items-center justify-center rounded-none border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none'
                        onClick={() => setActiveSlide('default')}>Back to main page</button>
                    <button
                        className='inline-flex items-center justify-center rounded-none border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none '
                        onClick={() => handleAccept(selectedCookieCategories)}>Accept Selection</button>
                </div>
            </div>
        )
    }


    return (
        <ModalContainer className={'md:max-w-5xl'} showC={[cookiePreferences === 'pending', () => { }]} disableClose={true}>
            <div className='p-4 md:p-6 '>
                {slides[activeSlide]}
            </div>
        </ModalContainer>
    )
}