import React, { useEffect, useState } from "react";
import { useRouter } from "next/router";
import Header from "@/blocks/Header";
import Footer from "@/blocks/Footer";

const WebsiteLayout = ({ children }) => {

    const [scrollY, setScrollY] = useState(0);
    const router = useRouter();

    useEffect(() => {
        if (window !== undefined) {
            window.addEventListener('scroll', () => setScrollY(window.scrollY))
        }
    })

    useEffect(() => {
        const script = document.createElement('script');

        script.src = 'https://assets.calendly.com/assets/external/widget.js';
        // script.async = true;

        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, [router.asPath]);




    const fixedBackground = router.pathname === '/data-privacy' || router.pathname === '/legal-notice'

    return (
        <>
            <Header background={fixedBackground} scrollY={scrollY} />
            {children}
            <Footer />
        </>
    );
};

export default WebsiteLayout;
