import React from 'react';
import Link from 'next/link';


export default function DynamicLink({
    data,
    className,
    children,
}) {
    const type = data?.type || 'default';
    const page = data?.page || null;
    const href = data?.url || null;
    const newTab = data?.newTab || false;
    const anchorLink = href?.startsWith('#');

    if (href && anchorLink) return (
        <a
            href={href}
            className={className}
            rel={newTab ? 'noopener noreferrer' : ''}
            target={newTab ? '_blank' : '_self'}
        >
            {children}
        </a>
    );

    return (
        <Link
            href={(() => {
                if (type === 'page') {
                    return `/${page?.slug === 'index' ? '' : (page?.slug || '')}`;
                } else {
                    return href || '';
                }
            })()}
            target={newTab ? '_blank' : '_self'}
            rel={newTab ? 'noopener noreferrer' : ''}
            className={className}>
            {children}
        </Link>
    );
};