// in cookieUtilities.js
export function setCookies(accepted) {

    // Delete all cookies apart from cookiePreferences
    document.cookie.split(";").forEach(function (c) {
        if (!c.trim().startsWith('cookiePreferences=')) {
            document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
        }
    }
    );


    if (accepted?.includes('analytics')) {
        // Analytics Cookie
        window._mfq = window._mfq || [];
        (function () {
            var mf = document.createElement("script");
            mf.type = "text/javascript"; mf.defer = true;
            mf.src = "//cdn.mouseflow.com/projects/0210f4ea-a5ed-492e-9e71-6d947cb8dec3.js";
            document.getElementsByTagName("head")[0].appendChild(mf);
        })();
    }

    // repeat for every cookie Category

    // Save Cookie Preferences
    document.cookie = `cookiePreferences=${accepted.join(',')}; path=/; max-age=31536000`;

}

export function initCookies() {
    const cookie = document.cookie.split(';').find(c => c.trim().startsWith('cookiePreferences='));
    if (cookie) {
        return (cookie.split('=')[1]);
    } else {
        return ('pending');
    }
}

export function registerConversion() {
    function gtag() { dataLayer.push(arguments); }
    gtag('js', new Date());

    gtag('event', 'conversion', { 'send_to': 'AW-11272582878/2__rCK6AitQYEN7tl_8p' });


}

// TODO Doesn't work!
export function registerPlausibleEvent(event) {
    window.plausible = window.plausible || function () { (window.plausible.q = window.plausible.q || []).push(arguments) }

    window.plausible(event)
}