import '@/styles/globals.css'
import '@/styles/DesignSystem.css'
import { DefaultSeo } from 'next-seo'
import WebsiteLayout from '@/layout/WebsiteLayout'

import { initCookies } from '@/utils/cookieUtilities';
import { atom, useAtom, Provider } from 'jotai'
import { CookieConsentModal } from '@/components/CookieConsentModal'
import { useEffect } from 'react';
import Head from 'next/head';
import Script from 'next/script';
import TagManager from 'react-gtm-module'
import PlausibleProvider from 'next-plausible'

export const cookiePreferencesAtom = atom('loading')

function MyApp({ Component, pageProps }) {
  const setCookiePreferences = useAtom(cookiePreferencesAtom)[1]
  useEffect(() => {
    setCookiePreferences(initCookies(document));
    TagManager.initialize({ gtmId: 'AW-11272582878' });
  }, [])
  return (
    <>
      <PlausibleProvider domain='sales.rizzsuites.com'>

        <CookieConsentModal />

        <WebsiteLayout>

          <DefaultSeo
            title="Rizz Suites"
            description='Fully Managed luxury apartments in the dominican republic. Hands off investment with 8% ROI per year.'
          />
          <Component {...pageProps} />
        </WebsiteLayout>

      </PlausibleProvider >
    </>

  )
}

function Wrapper(props) {
  return (
    <Provider>
      <MyApp {...props} />
    </Provider>
  )
}


export default Wrapper