import React from "react";
import CMSLink from "@/components/DynamicLink";
import { FaFacebook, FaInstagram, FaLinkedin, FaXing, FaYoutube } from "react-icons/fa";
import DynamicLink from "@/components/DynamicLink";
import Link from "next/link";

const Footer = ({ }) => {

  const footerData = {
    navigationLinks: [
      // {
      //   link: {
      //     label: "Back to top",
      //     href: "/",
      //   }
      // },
      // {
      //   link: {
      //     label: "Instyle Investments",
      //     href: "/",
      //   }
      // },
      // {
      //   link: {
      //     label: "Carreer",
      //     href: "/",
      //   }
      // },
    ],
    navigationLinks2: [
      {
        link: {
          label: "Legal Notice",
          href: "/legal-notice",
        }
      },
      {
        link: {
          label: "Data Privacy",
          href: "/data-privacy",
        }
      },
      {
        link: {
          label: "Contact",
          href: "/contact",
        }
      },
    ],
    socialMediaIcons: [
      // {
      //   icon: "linkedIn",
      //   link: "#"
      // },
      {
        icon: "facebook",
        alt: 'Visit us on Facebook',
        link: "https://www.facebook.com/profile.php?id=100084741805488"
      },
      {
        icon: "instagram",
        alt: 'Visit us on Instagram',
        link: "https://instagram.com/instyleinvestments.dr"
      },
    ]
  }

  const iconComponents = {
    linkedIn: FaLinkedin,
    youTube: FaYoutube,
    xing: FaXing,
    instagram: FaInstagram,
    facebook: FaFacebook,
  };

  const iconColors = {
    linkedIn: "#0B65C2",
    youTube: "#FF0000",
    xing: "#1D1D1B",
    instagram: "#E1306C",
    facebook: "#1977F2",
  };

  const SocialIcon = ({ IconComponent, color, link, alt }) => (
    <li className="flex justify-center items-center">
      <a href={link} target='_blank' rel='noopener noreferrer'>
        <IconComponent
          className="w-8 h-8 rounded-lg text-teracotta-600"
        />
        <span className="sr-only">{alt}</span>
      </a>
    </li>
  );

  return (
    <div className="">
      <div className="flex flex-col items-center py-6">
        <ul
          className={`flex  max-w-6xl my-10 mx-auto gap-8 p-2 justify-center`}
        >

          {/* {footerData?.socialMediaIcons?.map(({ icon, link, alt }, i) => {
            const IconComponent = iconComponents[icon];
            return IconComponent ? (
              <SocialIcon key={`socialcomponent-${i}`} IconComponent={IconComponent} link={link} />
            ) : null;
          })} */}


        </ul>
        <ul className="flex gap-5 flex-col sm:flex-row pb-2">
          {
            footerData?.navigationLinks?.map((link, i) => (
              <li key={`footerlink-${i}`}>
                <DynamicLink data={link.link} className={'text-teracotta-600'} >
                  {link.link.label}
                </DynamicLink>
              </li>))
          }
        </ul>
        <ul className="flex space-x-2  mt-6">
          {
            footerData?.navigationLinks2?.map((link, i) => (
              <li key={`navlink-${i}`} >
                <Link aria-label={`link to our ${link.link.label}`} href={link.link.href} className={'text-teracotta-700'} >
                  {link.link.label}
                </Link>
              </li>))
          }
        </ul>
      </div>


    </div>
  );
};

export default Footer;
